import { Box, Button, Divider, FilledInput, InputAdornment, Stack, styled } from '@mui/material';
import cssStyles from 'src/utils/cssStyles';
import React from 'react';
import Iconify from '../Iconify';
import searchIcon from '@iconify/icons-carbon/search';
import Routes from 'src/routes';
import { useRouter } from 'next/router';

const BarStyle = styled((props) => (
  <Stack
    spacing={2.5}
    alignItems={{ md: 'center' }}
    direction={{ xs: 'column', md: 'row' }}
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  maxWidth: 560,
  [theme.breakpoints.up('md')]: {
    ...cssStyles().bgBlur({
      blur: 4,
      opacity: 0.08,
      color: theme.palette.grey[500],
    }),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
}));

function ServicesForm() {
  const router = useRouter();

  return (
    <BarStyle>
      <Box
        textAlign="center"
        direction={{ xs: 'column', md: 'row' }}
        divider={
          <Divider
            orientation="vertical"
            sx={{
              height: 28,
              display: { xs: 'none', md: 'block' },
            }}
          />
        }
        spacing={2.5}
        sx={{ width: 1 }}
      >
        <FilledInput
          placeholder="Find by skills"
          endAdornment={
            <InputAdornment position="end">
              <Button
                size="large"
                variant="contained"
                onClick={() => router.push(Routes.search)}
                startIcon={<Iconify icon={searchIcon} sx={{ width: 20, height: 20 }} />}
                sx={
                  {
                    // display: { md: 'display' },
                  }
                }
              >
                Search
              </Button>

              {/* <Button
                  size="large"
                  variant="contained"
                  sx={{
                    px: 0,
                    minWidth: { xs: 1, md: 48 },
                    display: { md: 'none' },
                  }}
                >
                  <Iconify icon={searchIcon} sx={{ width: 24, height: 24 }} />
                </Button> */}
            </InputAdornment>
          }
          sx={{
            px: 1,
            width: 1,
            bgcolor: 'common.white',
            '&:hover, &.Mui-focused': {
              bgcolor: 'common.white',
            },
            '& .MuiFilledInput-input': {
              py: '18px',
              '&::placeholder': {
                color: 'grey.500',
              },
            },
          }}
        />
      </Box>

      {/* <Button
          variant="contained"
          size="large"
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 'auto' },
          }}
        >
          Analyse
        </Button> */}
    </BarStyle>
  );
}

export default ServicesForm;
