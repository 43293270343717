import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
// config
import { HEADER_MOBILE_HEIGHT, HEADER_DESKTOP_HEIGHT } from 'src/config';

// import { _testimonials } from '../_data/mock';
// layouts
// import Layout from 'src/layouts';
// components
// sections
import { NewsletterMarketing } from 'src/sections/newsletter';
import { TestimonialsMarketing } from 'src/sections/testimonials';
import { HomeServices, HomeServicesBenefits, HomeServicesHero, HomeServicesHowItWork, HomeServicesInclude } from 'src/sections/home';
import { MarketingFreeSEO } from 'src/sections/@marketing';
import { _testimonials } from '_data/mock';

const RootStyle = styled('div')(({ theme }) => ({
	paddingTop: HEADER_MOBILE_HEIGHT,
	[theme.breakpoints.up('md')]: {
		paddingTop: HEADER_DESKTOP_HEIGHT,
	},
}));

// ----------------------------------------------------------------------

// Home.propTypes = {
//     posts: PropTypes.array.isRequired,
// };

export default function Home() {
	return (
		<RootStyle>
			<HomeServicesHero />
			<HomeServices />
			<HomeServicesInclude />
			<HomeServicesBenefits />
			<HomeServicesHowItWork />
			<TestimonialsMarketing testimonials={_testimonials} />
			{/* <BlogMarketingLatestPosts posts={posts.slice(0, 4)} /> */}
			<MarketingFreeSEO />
			<NewsletterMarketing />
		</RootStyle>
	);
}
