import { StoreConst } from 'src/store/local';
import useRole from 'src/hooks/useRole';
// import DashboardFreelancer from 'src/sections/@dashboard/freelancer/dashboardFreelancer';
import { DashboardClient } from 'src/sections/@dashboard';
import { Home } from 'src/sections/home';
import DashboardFreelancer from 'src/sections/@dashboard/freelancer';
import { useRouter } from 'next/router';
import Routes from 'src/routes';

// ----------------------------------------------------------------------

function Dashboard() {
	const isClient = useRole(StoreConst.CLIENT);
	const router = useRouter();

	if (isClient) {
		router.push({
			pathname: Routes.client.search,
			query: { pid: 11 },
		});
	} else {
		router.push({
			pathname: Routes.freelancer.search,
			query: { pid: 22 },
		});
	}
	return <></>;
}

export default Dashboard;
