
import { useSelector } from "react-redux";

export default function useRole(role) {
    const user = useSelector(state => state.auth)

    if (user?.authData) {
        if (user?.authData?.role === role) {
            return true;
        }
        return false
    }
    return null
}



